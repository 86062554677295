import React, { useState } from 'react';
import { useMutation, useApolloClient } from '@apollo/client';
import { Redirect } from 'react-router-dom';
import { Button, FormInput } from '@podiumhq/podium-ui';

import { GENERATE_CODE } from '../../graphql/mutations';
import { USER_NEEDS_REDIRECT_QUERY } from '../../graphql/queries';
import { unsnoozeLoginPrompts, setCookies } from '../../lib/cookies';
import { isValidEmailOrPhone, formatIfPhone } from '../../lib/validation';
import {
  Header,
  Text,
  Input,
  ButtonHolder,
  HelpText,
  HelpTextClickable,
} from './styles';
import { ContentContainer } from '../App/styles';
import paths from '../../lib/paths';

const invalidUsernameText = 'Invalid email';
const missingUsernameText = 'Email or mobile number is required';

export default function RequestCode({
  emailOrPhone,
  setEmailOrPhone,
  setRedirectUrl,
  onHelpClick,
}) {
  const [missingUsername, setMissingUsername] = useState(false);
  const [invalidUsername, setInvalidUsername] = useState(false);
  const [codeGenerated, setCodeGenerated] = useState(false);
  const [ssoLoading, setSsoLoading] = useState(false);
  const client = useApolloClient();

  const [generateCode, { loading }] = useMutation(GENERATE_CODE, {
    onError() {
      // we do not want to expose if emails/phones are in our system or not
      setCodeGenerated(true);
    },
    onCompleted() {
      setCodeGenerated(true);
    },
  });

  const onEmailOrPhoneChange = (input) => {
    if (missingUsername && input) {
      setMissingUsername(false);
      setInvalidUsername(false);
    }

    if (invalidUsername && isValidEmailOrPhone(input)) {
      setInvalidUsername(false);
    }

    setEmailOrPhone(input);
  };

  async function getSSOEnabled() {
    try {
      // we move forward no matter what so there is no need to set loading to false this prevents
      // the text from jittering back to normal after the query finishes but before rendering
      setSsoLoading(true);
      const { data } = await client.query({
        query: USER_NEEDS_REDIRECT_QUERY,
        variables: { emailOrPhone },
      });

      const {
        userNeedsRedirect: { redirectUrl, ssoEnabled },
      } = data;

      if (ssoEnabled) {
        setCookies({ loggedInWithSso: true });
        setRedirectUrl(redirectUrl);
      }

      return ssoEnabled;
    } catch (_err) {
      return false;
    }
  }

  const onSubmit = async () => {
    const isValidUsername = isValidEmailOrPhone(emailOrPhone);

    setMissingUsername(!emailOrPhone);
    setInvalidUsername(!isValidUsername);

    if (!isValidUsername) return;

    const isSSOEnabled = await getSSOEnabled({ variables: { emailOrPhone } });

    if (isSSOEnabled) return;

    const identifier = formatIfPhone(emailOrPhone);

    await generateCode({
      variables: { emailOrPhone: identifier },
    });

    unsnoozeLoginPrompts();
  };

  const onEnter = (e) => {
    if (e.keyCode === 13) onSubmit();
  };

  return (
    <ContentContainer>
      {codeGenerated && <Redirect to={paths.submitCode} />}
      <Header>
        Let’s get you signed in.{' '}
        <span role="img" aria-label="Grinning Face">
          😀
        </span>
      </Header>
      <Text>
        Enter the email or mobile number associated with your Podium account and
        we’ll send you a temporary access code.
      </Text>
      <Input>
        <FormInput
          autoFocus
          id="emailOrPhoneInput"
          errorMessage={
            (missingUsername && missingUsernameText) ||
            (invalidUsername && invalidUsernameText)
          }
          label="Email or mobile number"
          name="username"
          onChange={(e) => onEmailOrPhoneChange(e.target.value)}
          onKeyDown={onEnter}
          tabindex="0"
          type="text"
          value={emailOrPhone}
        />
      </Input>
      <ButtonHolder>
        <Button
          fullwidth
          id="sendCodeButton"
          size="large"
          onClick={onSubmit}
          isLoading={loading || ssoLoading}
          loadingText="Sending code..."
        >
          Send code
        </Button>
      </ButtonHolder>
      <HelpText>
        <HelpTextClickable onClick={onHelpClick}>
          Need more help?
        </HelpTextClickable>
      </HelpText>
    </ContentContainer>
  );
}
