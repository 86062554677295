import React, { useCallback, useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import useScript from '../../lib/hooks/useScript';
import { GOOGLE_CLIENT_ID, GOOGLE_ACCOUNT_URI } from '../../lib/env';
import cookies from '../../lib/cookies';

export default function SignInWithGoogle({ onLogin, containerDimensions }) {
  const [refresh, setRefresh] = useState(0);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [autoLogin, setAutoLogin] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [gToken, setGToken] = useState('');
  const status = useScript({ src: GOOGLE_ACCOUNT_URI });

  const handleLogin = useCallback(
    ({ credential }) => {
      const decodedJwt = jwtDecode(credential);
      onLogin({ source: 'google', jwt: credential }, decodedJwt.email);
    },
    [onLogin],
  );

  const loadGoogle = useCallback(() => {
    window.google.accounts.id.initialize({
      auto_select: autoLogin,
      client_id: GOOGLE_CLIENT_ID,
      callback: handleLogin,
    });
    window.google.accounts.id.renderButton(
      document.getElementById('signInWithGoogleButton'),
      {
        theme: 'outline',
        width: containerDimensions?.width || null,
      },
    );

    window.google.accounts.id.prompt();
  }, [handleLogin, autoLogin, containerDimensions?.width]);

  useEffect(() => {
    if (scriptLoaded) return;
    if (status.loaded && !status.error) {
      setScriptLoaded(true);
    }
  }, [status, scriptLoaded]);

  useEffect(() => {
    const jwt = cookies.get('gToken');
    if (jwt) {
      setGToken(jwt);
    }
  }, []);

  useEffect(() => {
    if (!enabled) return;
    if (gToken) {
      const decodedJwt = jwtDecode(gToken);
      const unixNow = Math.floor(Date.now() / 1000);
      if (decodedJwt.exp > unixNow) {
        onLogin({ source: 'google', jwt: gToken }, decodedJwt.email);
      } else {
        setAutoLogin(true);
      }
    }
  }, [onLogin, gToken, enabled]);

  useEffect(() => {
    if (!!GOOGLE_ACCOUNT_URI && !!GOOGLE_CLIENT_ID) {
      setEnabled(true);
    }
  }, []);
  useEffect(() => {
    if (!enabled || !scriptLoaded) return;

    if (window?.google?.accounts) {
      loadGoogle();
    } else {
      setTimeout(() => {
        setRefresh(refresh + 1);
      }, 250);
    }
  }, [scriptLoaded, refresh, loadGoogle, enabled, containerDimensions]);

  return (
    enabled && (
      <div
        id="signInWithGoogleButton"
        style={{
          marginTop: '24px',
        }}
      />
    )
  );
}
