window.env = window.env || {};

export const FREE_TRIALS_PAGE =
  window.env.REACT_APP_FREE_TRIALS_PAGE ||
  process.env.REACT_APP_FREE_TRIALS_PAGE ||
  'http://localhost:1998';
export const STORMCROW_HOST =
  window.env.REACT_APP_STORMCROW_HOST ||
  process.env.REACT_APP_STORMCROW_HOST ||
  'http://localhost:4100';
export const MITHRANDIR_URL =
  window.env.REACT_APP_MITHRANDIR_URL ||
  process.env.REACT_APP_MITHRANDIR_URL ||
  'http://localhost:1892';
export const PADAWAN_HOST =
  window.env.REACT_APP_PADAWAN_HOST ||
  process.env.REACT_APP_PADAWAN_HOST ||
  'http://localhost:9901';
export const KAZAAM_HOST =
  window.env.REACT_APP_KAZAAM_HOST ||
  process.env.REACT_APP_KAZAAM_HOST ||
  'http://localhost:9100';
export const USE_SECURE_COOKIES =
  (window.env.REACT_APP_USE_SECURE_COOKIES ||
    process.env.REACT_APP_USE_SECURE_COOKIES ||
    'false') === 'true';
export const LOAD_EXTERNAL_SCRIPTS =
  window.env.REACT_APP_LOAD_EXTERNAL_SCRIPTS ||
  process.env.REACT_APP_LOAD_EXTERNAL_SCRIPTS ||
  'false';
export const APP_VERSION =
  window.env.REACT_APP_VERSION || process.env.REACT_APP_VERSION || '1';

export const RECAPTCHA_SITE_KEY =
  window.env.REACT_APP_RECAPTCHA_SITE_KEY ||
  process.env.REACT_APP_RECAPTCHA_SITE_KEY ||
  '';

export const BYPASS_CAPTCHA =
  (window.env.REACT_APP_BYPASS_CAPTCHA ||
    process.env.REACT_APP_BYPASS_CAPTCHA ||
    'false') === 'true';

export const AMPLITUDE_TOKEN =
  window.env.REACT_APP_AMPLITUDE_TOKEN ||
  process.env.REACT_APP_AMPLITUDE_TOKEN ||
  '';

export const GOOGLE_CLIENT_ID =
  window.env.REACT_APP_GOOGLE_CLIENT_ID ||
  process.env.REACT_APP_GOOGLE_CLIENT_ID ||
  '';

export const GOOGLE_ACCOUNT_URI =
  window.env.REACT_APP_GOOGLE_ACCOUNT_URI ||
  process.env.REACT_APP_GOOGLE_ACCOUNT_URI ||
  'https://accounts.google.com/gsi/client';

export const APPLE_CLIENT_ID =
  window.env.REACT_APP_APPLE_CLIENT_ID ||
  process.env.REACT_APP_APPLE_CLIENT_ID ||
  '';

export const APPLE_AUTH_URI =
  window.env.REACT_APP_APPLE_AUTH_URI ||
  process.env.REACT_APP_APPLE_AUTH_URI ||
  'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';

export const DATADOG_APPLICATION_ID =
  window.env.REACT_APP_DATADOG_APPLICATION_ID ||
  process.env.REACT_APP_DATADOG_APPLICATION_ID ||
  null;

export const DATADOG_CLIENT_TOKEN =
  window.env.REACT_APP_DATADOG_CLIENT_TOKEN ||
  process.env.REACT_APP_DATADOG_CLIENT_TOKEN ||
  null;

export const ENV_NAME =
  window.env.REACT_APP_ENV_NAME ||
  process.env.REACT_APP_ENV_NAME ||
  'local-dev';
